import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { CacheProvider } from '@emotion/react';
import { log, startPerformanceMeasure } from '@guardian/libs';
import { createElement } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { ConfigProvider } from '../../components/ConfigContext';
/**
 * This function dynamically imports and then hydrates a specific component in
 * a specific part of the page
 *
 * If the content being hydrated is not present in the dom then React renders
 * it. This is how portals (non server side rendered content) work
 *
 * @param name The name of the component we want to hydrate
 * @param data The deserialised props we want to use for hydration
 * @param element The location on the DOM where the component to hydrate exists
 * @param emotionCache An instance of an emotion cache to use for the island
 * @param config Application configuration to be passed to the config context for the hydrated component
 */
export const doHydration = async (name, data, element, emotionCache, config) => {
    // If this function has already been run for an element then don't try to
    // run it a second time
    if (element.dataset.islandStatus !== undefined)
        return;
    else
        element.dataset.islandStatus = 'identified';
    const { endPerformanceMeasure: endImportPerformanceMeasure } = startPerformanceMeasure('dotcom', name, 'import');
    await import(
    /* webpackInclude: /\.importable\.tsx$/ */
    /* webpackChunkName: "[request]" */
    `../../components/${name}.importable`)
        .then((module) => {
        /** The duration of importing the module for this island */
        const importDuration = endImportPerformanceMeasure();
        element.dataset.islandStatus = 'imported';
        const { endPerformanceMeasure: endIslandPerformanceMeasure } = startPerformanceMeasure('dotcom', name, 'island');
        hydrateRoot(element, _jsx(ConfigProvider, { value: config, children: _jsx(CacheProvider, { value: emotionCache, children: createElement(module[name], data) }) }));
        /** The duration of rendering or hydrating this island */
        const islandDuration = endIslandPerformanceMeasure();
        return { importDuration, islandDuration };
    })
        .then(({ importDuration, islandDuration }) => {
        if (!('getEntriesByType' in window.performance))
            return;
        element.dataset.islandStatus = 'hydrated';
        log('dotcom', `🏝 <${name} /> hydrated in ${islandDuration}ms (imported in ${importDuration}ms)`);
    })
        .catch((error) => {
        element.dataset.islandStatus = undefined; // remove any island status
        if (name && error.message.includes(name)) {
            console.error(`🚨 Error importing ${name}. Components must live in the root of /components and follow the [MyComponent].importable.tsx naming convention 🚨`);
        }
        throw error;
    });
};
